import { Pagination } from "./product.interface";

export interface MainResponseList {
  currentPage: number;
  totalElements: number;
  totalPages: number;
}

export interface DataListInternalContract {
  createdDate: string;
  modifiedDate: string;
  branchId: string;
  branchName: string;
  documentNo: string;
  contractDate: string;
  revised: number;
  picName: string;
  customerId: string;
  customerName: string;
  currency: string;
  rentPeriod: string;
  totalRentPrice: number | string;
  status: string;
  id: string;
  key?: number;
  no?: number;
  alreadyDue?: boolean;
}

export interface SalesOrderData {
  id: string;
  createdDate: string;
  currency: string;
  customerBillToAddress: string;
  customerId: string;
  customerName: string;
  customerPoNumber: string;
  customerShipToAddress: string;
  date: string;
  deliveryDate: string;
  internalContractId: string;
  internalContractNo: string;
  location: string;
  modifiedDate: string;
  notes: string;
  salesId: string;
  salesName: string;
  salesOrderId: string;
  salesOrderNo: string;
  salesType: string;
  states: string;
  termOfPayment: number;
  total: number | string;
  key?: number;
}
export interface SalesOrderDataTracking {
  state: string;
  key?: number;
  qtyOutstanding: number;
  qtyDelivery: number;
  qtyBilled: number;
  documentReference: string;
  documentModel: string;
  documentReferenceId: string;

  relatedDocs?: SalesOrderDataTracking[];
  createdDate?: string;
}

export interface SalesOrderList extends MainResponseList {
  data: SalesOrderData[];
}

export interface SalesOrderHeaderList extends MainResponseList {
  data: SalesOrderHeaderData[];
}

export interface SalesOrderHeaderData {
  id: string;
  branchWarehouse: string;
  branchWarehouseId: string;
  createdDate: string;
  currency: string;
  customerBillToAddress: string;
  customerId: string;
  customerName: string;
  customerPoNumber: string;
  customerShipToAddress: string;
  date: string;
  deliveryDate: string;
  documentNumber: string;
  endRent: string;
  grandTotal: string;
  internalContractId: string;
  internalContractNo: string;
  modifiedDate: string;
  notes: string;
  salesId: string;
  salesName: string;
  salesType: string;
  startRent: string;
  states: string;
  taxCalculation: string;
  termOfPayment: number | string;
  total: number | string;
  totalDiscount: number | string;
  totalTax: number | string;
}

export interface SalesOrderListTracking extends MainResponseList {
  data: SalesOrderDataTracking[];
}

export interface LineDataReportInternalContract {
  unitCode: string;
  equipment: string;
  merk: string;
  type: string;
  qty: number;
  rentPrice: string;
}

export interface DataListReportInternalContract {
  no?: number;
  key?: number;
  branchName: string;
  documentNo: string;
  contractDate: string;
  revised: number;
  picName: string;
  customerName: string;
  currencyCode: string;
  billingPeriod: number;
  billingType: string;
  rentPeriod: number;
  startRent: string;
  salesName: string;
  salary: string;
  totalRentPrice: string;
  unitCode: string;
  equipment: string;
  merk: string;
  type: string;
  qty: number;
  rentPrice: string;
  status: string;
  lineDTO: LineDataReportInternalContract[];
  children: LineDataReportInternalContract[];
}

export interface ResponseGetListInternalContract extends MainResponseList {
  data: DataListInternalContract[];
}

export interface ResponseGetListReminderContract extends MainResponseList {
  data: DataListReminderContract[];
}

export interface DataListReminderContract {
  key: number;
  createdDate: string;
  modifiedDate: string;
  icNumber: string;
  bapuNumber: string;
  startRent: string;
  endRent: string;
  id: string;
  rentPeriod?: string; // custom
  alreadyDue?: boolean; // custom
  receiveDate?: string; // custom
}

export interface ResponseGetListReportInternalContract
  extends MainResponseList {
  data: DataListReportInternalContract[];
}

export interface InternalContractDetailList {
  key?: number;
  unitId: string;
  unitCode: string;
  unitBackup: boolean;
  unitBackupNumber: number;
  unitParentId: string;
  unitCodeParent: string;
  unitCodeIsParent: boolean;
  equipment: string;
  merk: string;
  type: string;
  qty: number;
  uomId: string;
  uomUnit: string;
  rentPrice: number;
  subTotal: number;
  endContract: boolean;
  serialNumber?: string;
  locationId?: string;
  location?: string;
  id: string;
  specification: string;
  shipTo: string;
}

export enum EnumStatus {
  New = "New",
  Draft = "Draft",
  NeedApproval = "Need Approval",
  Rejected = "Rejected",
  Revised = "Revised",
  Ended = "Ended",
  Approved = "Approved",
  PartialEnded = "Partial Ended",
  ChangeUnit = "Change Unit",
  ChangeLocation = "Change Location",
  Cancelled = "Cancelled",
}

export interface DetailInternalContract {
  isAllBastPosted: boolean;
  service: boolean;
  periodType: string;
  shift1: boolean;
  shift2: boolean;
  shift3: boolean;
  createdDate: string;
  modifiedDate: string;
  documentNo: string;
  branchId: string;
  branchName: string;
  referenceNo: string;
  contractDate: string;
  customerId: string;
  customerName: string;
  shipTo: string;
  billTo: string;
  picName: string;
  currencyId: string;
  currencyCode: string;
  rentPeriod: number;
  startRent: string;
  endRent: string;
  rentPeriodRange: string;
  billingPeriod: number;
  billingType: string;
  termOfCondition: string;
  fileContract: string;
  salesId: string;
  salesName: string;
  salary: number;
  status: EnumStatus;
  revised: number;
  approverId: string;
  approverName: string;
  createdBy: string;
  notes: string;
  totalRentPrice: number;
  internalContractDetailList: InternalContractDetailList[];
  productServices: ProductServicesDetail[];
  id: string;
  isAcceptanceOfOrder: boolean;
  isSo: boolean;
  isCanCancel: boolean;
  totalPriceDetail: number;
  totalPriceProductService: number;
  historyStatus?: string;
}

export interface ProductServicesDetail {
  assetLocation: null;
  createdDate: string;
  endContract: boolean;
  equipment: null;
  id: string;
  location: null;
  merk: null;
  modifiedDate: string;
  productCode: string;
  productId: string;
  productName: string;
  qty: number;
  rentPrice: number;
  serialNumber: null;
  shipTo: null;
  specification: null;
  subTotal: number;
  type: string;
  unitBackup: null;
  unitBackupNumber: null;
  unitCode: null;
  unitCodeIsParent: boolean;
  unitCodeParent: null;
  unitId: null;
  unitParentId: null;
  uomId: string;
  uomUnit: string;
}

export interface ContractDetail {
  unitId: string;
  unitParentId: string | null;
  unitBackup: boolean;
  equipment: string;
  merk: string;
  type: string;
  qty: number;
  uom: string;
  rentPrice: number;
  subTotal: number;
  endContract: boolean;
  location: string;
  shipTo: string;
}

export interface RequestInternalContract {
  periodType: string;
  branchId: string;
  referenceNo: string;
  contractDate: string;
  customerId: string;
  shipTo: string;
  billTo: string;
  picName: string;
  currencyId: string;
  rentPeriod: number;
  startRent: string;
  endRent: string;
  billingType: string;
  termOfCondition: string;
  fileContract: string;
  salesId: string;
  service: boolean;
  status: string;
  approverId: string;
  notes: string;
  total: number;
  contractDetails: ContractDetail[];
  productServices: ProductServices[];
  deleteLineId: string[];
}

export interface ProductServices {
  productServiceId: string;
  productId: string;
  // type: string,
  qty: string | number;
  uom: string;
  rentPrice: number;
  subTotal: number;
}

export interface PickingListResponseDTO {
  pickingListId: string;
  pickingListNumber: string;
}

/**
 * @deprecated
 * new model {@linkcode SalesOrderCloseRequestDto}
 */
export interface CloseSo {
  reasonToClose: string;
}
/**
 * @deprecated
 * new model {@linkcode SalesOrderCreateRequestDto}
 */
export interface RequestDataSo {
  currency: string;
  customerBillToAddress: string;
  customerId: string;
  customerPoNumber: string;
  customerShipToAddress: string;
  date: string;
  deliveryDate: string;
  internalContractId: string;
  notes: string;
  salesId: string;
  salesOrderLines: SalesOrderLines[];
  salesType: string;
  taxCalculation: string;
  state: string;
  termOfPayment: number;
  total: number;
  totalTax: number;
  totalDiscount: number;
  grandTotal: number;
  deletedSalesOrderLineIds?: string[];
  id?: string;
  branchWarehouseId?: string;
  currencyRate: number;
}

/**
 * @deprecated
 * new model {@linkcode SalesOrderLineRequestDto}
 */
export interface SalesOrderLines {
  assetId?: string;
  backupUnit?: boolean;
  locationId?: string | null;
  percentDiscount?: number | string;
  discountValue?: number | string;
  price?: number | string;
  productId?: string;
  qty?: number | string;
  subTotal?: number | string;
  unitTaxRate?: number | string;
  uomId?: string;
  internalContractLineId?: string;
  id?: string;
  serialNumber?: string;
  customerLocation?: string;
  qtyAvailable?: number;
  taxCode?: string;
}

export interface ListDetailHistoryInternalContract extends MainResponseList {
  data: DetailInternalContract[];
}

export interface PickingListList extends MainResponseList {
  data: PickingListData[];
}

export interface PickingListData {
  createdDate: string;
  customerName: string;
  id: string;
  modifiedDate: string;
  pickingListNo: string;
  referenceNo: string;
  key?: number;
  transactionType?: string;
  referenceId: string;
}

export interface DeliveryOrderList extends MainResponseList {
  data: DeliveryOrderData[];
}

export interface DeliveryOrderData {
  createdDate: string;
  customerName: string;
  customerId: string;
  date: string;
  documentNumber: string;
  id: string;
  modifiedDate: string;
  salesOrderNumber: string;
  workOrderNumber: string;
  supplierExpeditionName: string;
  key?: number;
}

export interface PickingListTransaction extends MainResponseList {
  data: PickingListTransactionData[];
}

export interface PickingListTransactionData {
  documentNumber: string;
  id: string;
  key?: number;
}

export interface PickingListRequest {
  data: PickingListTransactionData[];
}

export interface PickingListRequestLine {
  documentNumber: string;
  id: string;
  key?: number;
}

export interface PickingListDetail {
  createdDate: string;
  customerName: string;
  description: string;
  id: string;
  modifiedDate: string;
  personInCharge: string;
  customerBillTo: string;
  customerShipTo: string;
  pickingListLines: PickingListLine[];
  pickingListNo: string;
  recipient: string;
  transactionRefDate: string;
  transactionType: string;
  transcationRefNo: string;
  key?: number;
  customerId: string;
}

export interface PickingListLine {
  no?: string;
  createdDate?: string;
  id?: string;
  modifiedDate?: string;
  location: string;
  merk: string;
  warehouseLocationDesc: string;
  partNumber: string;
  productCode: string;
  productName: string;
  qty: number | string;
  qtyAvailable: number | string;
  qtyPick: number | string;
  serialNumber: string;
  type: string;
  uom: string;
  key?: number;
  assetId?: string;
  locationId?: string;
  productId?: string;
  uomId?: string;
  assetParentId?: string;
}

export interface RequestPickingList {
  personInCharge: string;
  recipient: string;
  transactionType: string;
  soId: string;
  woId: string;
  description: string;
  lineRequestDTOList: RequestPickingListLine[];
}

export interface RequestPickingListLine {
  assetId: string;
  locationId: string;
  location: string;
  partNumber: string;
  productId: string;
  qty: number;
  qtyAvailable: number;
  qtyPick: number;
  serialNumber: string;
  type: string;
  uom: string;
  assetParentId?: string;
}

/**
 * @deprecated
 * new model {@linkcode SalesOrderReportResponseDto}
 */
export interface ResponseSalesOrderReportList extends Pagination {
  data: SalesOrderReportData[];
}

export interface ResponseTrackingIc extends Pagination {
  data: DataTrackingIc[];
}

export interface DataTrackingIc {
  createdDate: null;
  modifiedDate: null;
  customerId: string;
  customerName: string;
  icId: string;
  icNumber: string;
  icDate: string;
  icStatus: string;
  ic_line_id: string;
  equipment: string;
  price: number;
  unitId: string;
  unitCode: string;
  soId: string;
  soNumber: string;
  soDate: string;
  doId: string;
  doNumber: string;
  doDate: string;
  bastId: string;
  bastNumber: string;
  bastDate: string;
  bastPostDate: null;
  bastState: string;
  tracking: string;
  id: null;
}

/**
 * @deprecated
 * new model {@linkcode SalesOrderDODetailsReportDto}
 */
export interface DeliveryOrderDetail {
  deliveryDate: string;
  deliveryNumber: string;
  qtyDO: number | string;
  invoiceDate: string;
  invoiceNumber: string;
  qtyInvoice: number | string;
  invoiceAmount: number | string;
  taxInvoiceNumber: string;
  status: string;
}

/**
 * @deprecated
 * new model {@linkcode SalesInvoiceArDetailsReportDto}
 */
export interface InvoiceArDetail {
  invoiceAmount: number | string;
  invoiceDate: string;
  invoiceNumber: string;
  qtyInvoice: number | string;
  status: string;
  taxInvoiceNumber: string;
}

/**
 * @deprecated
 * new model {@linkcode SalesOrderReportResponseDto}
 */
export interface SalesOrderReportData {
  date: string;
  salesNumber: string;
  customerName: string;
  warehouseBranch: string;
  description: string;
  salesName: string;
  qtySO: number;
  deliveryOrderDetails: DeliveryOrderDetail[];
  invoiceArDetails: InvoiceArDetail[];
  soLineDetails: ISalesOrderLineDetailsReportDTO[];
  key?: number;
}

/**
 * @deprecated
 * new model {@linkcode SalesOrderLineDetailsReportDto}
 */
export interface ISalesOrderLineDetailsReportDTO {
  partNumber: string;
  price: number;
  partId: string;
  equipment: string;
  jenis: string;
  brand: string;
  type: string;
  capacity: string;
  serialNumber: string;
  qtySo: number;
  uomId: string;
  productUom: string;
}

export interface DataResponseGetListDoReady {
  createdDate: string;
  modifiedDate: string;
  doNumber: string;
  doDate: string;
  transactionNumber: string;
  customerName: string;
  qty: number;
  id: string;
}

export interface ResponseGetListDoReady extends MainResponseList {
  data: DataResponseGetListDoReady[];
}

export interface LineDtosResponseDoReadyToPrint {
  id: string;
  productCode: string;
  productName: string;
  serialNumber: string;
  merk: string;
  type: string;
  capacity: string;
  bastNumber: string;
  lastPrintBy: string;
  isAsset: boolean;
}

export interface ResponseDoReadyToPrint {
  createdDate: string;
  modifiedDate: string;
  doNumber: string;
  doDate: string;
  supplierExpeditionCode: string;
  supplierExpeditionName: string;
  supplierAddress: string;
  truckOrShipNo: string;
  description: string;
  transactionNumber: string;
  icNumber: string;
  contractPeriod: string;
  customerName: string;
  customerShipTo: string;
  customerBillTo: string;
  phoneNumber: string;
  customerPicName: string;
  isAsset: boolean;
  lineDTOS: LineDtosResponseDoReadyToPrint[];
  id: string;
}

export interface DataResponseBastHasBeenCreated {
  key?: number;
  no?: number;
  createdDate: string;
  modifiedDate: string;
  bastNumber: string;
  bastDate: string;
  soNumber: string;
  customerName: string;
  state: string;
  id: string;
}

export interface ResponseBastHasBeenCreated extends MainResponseList {
  data: DataResponseBastHasBeenCreated[];
}

export type BastStateValue =
  | "NEW"
  | "OPERATIONAL_SUBMITTED"
  | "SALES_SUBMITTED"
  | "POSTED";
export type BastStateKey =
  | "New"
  | "Operational Submitted"
  | "Sales Submitted"
  | "Posted";

export interface DataResponseBastState {
  value: BastStateValue;
  key: BastStateKey;
}

export interface ResponseBastState extends MainResponseList {
  data: DataResponseBastState[];
}

export interface LineDtosDetailBastHasBeenCreated {
  id: string;
  unitCode: string;
  serialNumber: string;
  merk: string;
  type: string;
  capacity: string;
  specification: string;
  parent: string;
  accessories: string[];
}

export enum TypeStateBastBalikan {
  New = "NEW",
  OPERATIONAL_SUBMITTED = "OPERATIONAL_SUBMITTED",
  SALES_SUBMITTED = "SALES_SUBMITTED",
  POSTED = "POSTED",
}

export interface ResponseDetailBastHasBeenCreated {
  id: string;
  bastNumber: string;
  bastDate: string;
  salesId: string;
  salesName: string;
  receivedBySalesDate: string;
  billingType: string;
  billingDate: string;
  contractPeriod: string;
  soNumber: string;
  customerId: string;
  customerName: string;
  customerShipAddress: string;
  receivedByCustomerDate: string;
  receivedByCustomer: string;
  position: string;
  dateBack: string;
  approvedBy: string;
  description: string;
  doDate: string;
  state: TypeStateBastBalikan;
  lineDTOS: LineDtosDetailBastHasBeenCreated[];
}

export interface RequestBastHasBeenCreated {
  isSales: boolean;
  receivedByCustomerDate: string;
  receivedByCustomer: string;
  position: string;
  dateBack: string;
  approvedBy: string;
  description: string;
  salesId: string;
  receivedBySalesDate: string;
  billingDate: string;
  state: string;
  contractPeriod: string;
  startRent: string;
  endRent: string;
}

export interface DataResponseBastWithdrawl {
  key?: number;
  id: string;
  icNumber: string;
  customerName: string;
  rentPeriod: string;
  qty: number;
}

export interface ResponseBastWithdrawl extends MainResponseList {
  data: DataResponseBastWithdrawl[];
}

export interface LineDtosDetailBastWithdrawl {
  key?: number;
  no?: number;
  id: string;
  productCode: string;
  productName: string;
  serialNumber: string;
  merk: string;
  type: string;
  qty: string;
  withdrawlNumber: string;
  lastPrintBy: string;
}

export enum RentType {
  Monthly = "Monthly",
  Daily = "Daily",
}

export interface DetailBastWithdrawl {
  id: string;
  icNumber: string;
  contractDate: string;
  customerId: string;
  customerNumber: string;
  customerName: string;
  customerAddress: string;
  rentPeriodRange: string;
  rentType: RentType;
  rentPeriod: number;
  notes: string;
  referenceNumber: string;
  lineDTOS: LineDtosDetailBastWithdrawl[];
}

export interface DataResponseBastExchange {
  key?: number;
  no?: number;
  id: string;
  icNumber: string;
  customerName: string;
  rangeRentPeriod: string;
  qty: number;
}

export interface ResponseBastExchange extends MainResponseList {
  data: DataResponseBastExchange[];
}

export interface IcChangeUnitDTOS {
  key?: number;
  no?: number;
  unitCodeFrom: string;
  unitCodeTo: string;
  merkFrom: string;
  merkTo: string;
  typeFrom: string;
  typeTo: string;
  snFrom: string;
  snTo: string;
  modelFrom: string;
  modelTo: string;
  specificationFrom: string;
  specificationTo: string;
  capacityFrom: string;
  capacityTo: string;
}

export interface ResponseDetailBastExchange {
  id: string;
  icNumber: string;
  replacementDate: string;
  replacementNumber: string;
  customerName: string;
  claim: string;
  replacementFee: number;
  notes: string;
  icChangeUnitDTOS: IcChangeUnitDTOS[];
}

export interface RequestUpdateBastExchange {
  claim: string;
  replacementFee: number;
  notes: string;
}

export interface DataResponseListInternalContractChangeLocation {
  createdDate: string;
  modifiedDate: string;
  icNumber: string;
  customerName: string;
  branchName: string;
  baplNumber: string;
  rentPeriod: string;
  qty: number;
  id: string;
}

export interface ResponseListInternalContractChangeLocation
  extends MainResponseList {
  data: DataResponseListInternalContractChangeLocation[];
}

export interface LineDTODetailICChangeLocation {
  unitCode: string;
  equipment: string;
  serialNumber: string;
  merk: string;
  type: string;
  qty: number;
}

export interface DetailInternalContractChangeLocation {
  createdDate: string;
  modifiedDate: string;
  icNumber: string;
  contractDate: string;
  customerNumber: string;
  customerName: string;
  customerAddressOld: string;
  customerLocationOld: string;
  customerAddressNew: string;
  customerLocationNew: string;
  baplNumber: string;
  lastPrintBy: string;
  lineDTOS: LineDTODetailICChangeLocation[];
  id: string;
}

export interface DataResponseReportUnitRented {
  branchName: string;
  customerName: string;
  customerLocation: string;
  unitCode: string;
  equipment: string;
  merk: string;
  type: string;
  serialNumber: string;
  model: string;
  capacity: string;
  specification: string;
  icNumber: string;
  bastNumber: string;
  bastDate: string;
}
export interface ResponseReportUnitRented extends MainResponseList {
  data: DataResponseReportUnitRented[];
}

export interface ResponseReportUnitPosition {
  branchName: string;
  capacity: string;
  customerCode: string;
  customerLocation: string;
  customerName: string;
  equipment: string;
  merk: string;
  model: string;
  qty: number;
  serialNumber: string;
  specification: string;
  status: string;
  type: string;
  unitCode: string;
  warehouse: string;
  yearPlacement: string;
}

export interface DataResponseGetListInternalContractSo {
  id: string;
  doNumber: string;
  doDate: string;
  transactionNumber: string;
  customerName: string;
  qty: number;
}

export interface ResponseGetListInternalContractSo extends MainResponseList {
  data: DataResponseGetListInternalContractSo[];
}

export interface ResponseListBastByIc {
  contractPeriod: string;
  createdDate: string;
  customerBillTo: string;
  customerName: string;
  customerPicName: string;
  customerShipTo: string;
  description: string;
  doDate: string;
  doNumber: string;
  icNumber: string;
  id: string;
  isAsset: boolean;
  lineDTOS: LineDtosResponseDoReadyToPrint[];
  modifiedDate: string;
  phoneNumber: string;
  supplierAddress: string;
  supplierExpeditionCode: string;
  supplierExpeditionName: string;
  transactionNumber: string;
  truckOrShipNo: string;
}
