var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    {
      attrs: {
        showSearch: true,
        filterOption: false,
        allowClear: true,
        loading: _vm.contactOption.fetching,
        placeholder: _vm.placeholder,
        disabled: _vm.disabled,
        value: _vm.value,
        dropdownMatchSelectWidth: false
      },
      on: { search: _vm.searchContact, change: _vm.handleChange }
    },
    _vm._l(_vm.contactOption.data, function(item) {
      return _c(
        "a-select-option",
        {
          key: item.id,
          attrs: { value: item.id },
          on: {
            click: function($event) {
              return _vm.onMeta(item)
            }
          }
        },
        [
          _c(
            "a-tooltip",
            [
              _c("template", { slot: "title" }, [
                _vm._v(" " + _vm._s(item.fullName) + " ")
              ]),
              _vm._v(" " + _vm._s(item.fullName) + " ")
            ],
            2
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }